import { useEffect, useRef } from 'react'
import { useRouter } from 'next/router'

declare global {
  interface Window {
    googletag: any
  }
}

const INTERSTITIAL_AD_UNIT = '/21830119956,10216148/opopular.com.br/AL_0214n_INTERSTITIAL_DESK'
const ALLOWED_EDITORIAS = ['magazine', 'economia', 'esporte']
const clientSlug = process.env.NEXT_PUBLIC_WEBAPP_CLIENT_SLUG

const InterstitialAd = () => {
  const router = useRouter()
  const hasDisplayedRef = useRef(false)

  const shouldShowAd = (path: string): boolean => {
    const normalizedPath = path.toLowerCase()
    const matchesEditorias = ALLOWED_EDITORIAS.some((editoria) =>
      normalizedPath.includes(`/${editoria}`)
    )
    const startsWithOneDot = /^\/1\./.test(normalizedPath)
    return matchesEditorias || startsWithOneDot
  }

  const waitForGoogletag = (): Promise<void> => {
    return new Promise((resolve) => {
      const interval = setInterval(() => {
        if (window.googletag?.cmd && window.googletag?.defineOutOfPageSlot) {
          clearInterval(interval)
          resolve()
        }
      }, 50)
    })
  }

  useEffect(() => {
    if (clientSlug !== 'opopular') {
      console.log('[GPT] Interstitial bloqueado — clientSlug diferente de opopular:', clientSlug)
      return
    }

    if (!shouldShowAd(router.asPath)) {
      console.log('[GPT] Interstitial bloqueado — path não permitido:', router.asPath)
      return
    }

    waitForGoogletag().then(() => {
      console.log('[GPT] googletag disponível. Iniciando configuração do interstitial.')
      const googletag = window.googletag

      googletag.cmd.push(() => {
        const interstitialSlot = googletag.defineOutOfPageSlot(
          INTERSTITIAL_AD_UNIT,
          googletag.enums.OutOfPageFormat.INTERSTITIAL
        )

        if (interstitialSlot) {
          interstitialSlot
            .addService(googletag.pubads())
            .setConfig({
              interstitial: {
                triggers: {
                  navBar: false,
                  unhideWindow: true,
                },
              },
            })

          googletag.enableServices()

          hasDisplayedRef.current = true
          console.log('[GPT] Interstitial slot definido com sucesso')
        } else {
          console.warn('[GPT] Slot interstitial não foi definido — possivelmente dispositivo incompatível')
        }
      })
    })
  }, [])

  useEffect(() => {
    const handleRouteChange = (url: string) => {
      if (clientSlug !== 'opopular') {
        console.log('[GPT] Route mudou mas clientSlug não é opopular:', clientSlug)
        return
      }

      if (!shouldShowAd(url)) {
        console.log('[GPT] Route mudou mas interstitial está bloqueado:', url)
        return
      }

      if (!hasDisplayedRef.current) return

      if (window.googletag?.cmd) {
        window.googletag.cmd.push(() => {
          console.log('[GPT] Tentando exibir interstitial após route change para:', url)
          try {
            window.googletag.pubads().refresh()
          } catch (error) {
            console.error('[GPT] Erro ao tentar exibir o interstitial:', error)
          }
        })
      }
    }

    router.events.on('routeChangeComplete', handleRouteChange)
    return () => {
      router.events.off('routeChangeComplete', handleRouteChange)
    }
  }, [router])

  return null
}

export default InterstitialAd
